<template>
  <b-card v-if="$route.name != 'edit-club'">
    <section-menu
      :name-module="nameModule"
      :sections-in-tab="sectionsInTab"
      @assign-translate-in-x="assignTranslateInX"
    />
    <b-row
      class="pt-1"
      :class="{'i-translate-in-pi' : showTransform}"
    >
      <b-col>
        <router-view />
      </b-col>
    </b-row>
  </b-card>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import SectionMenu from '@/views/partials/menu/SectionMenu.vue'

export default {
  components: {
    SectionMenu,
  },

  data() {
    return {
      showTransform: false,
      sectionsInTab: [
        { id: 1, route: 'corporate', name: 'routes.documentalCollectionRoute.corporate' },
        { id: 2, route: 'society', name: 'corporate.society' },
        { id: 3, route: 'assemblies', name: 'routes.documentalCollectionRoute.assemblies' },
        { id: 4, route: 'power-of-attorney', name: 'routes.documentalCollectionRoute.powers' },
        { id: 5, route: 'representative', name: 'routes.documentalCollectionRoute.representatives' },
        { id: 6, route: 'intellectual-property', name: 'routes.documentalCollectionRoute.intellectualProperty' },
      ],
      nameModule: 'routes.documentalCollectionRoute.documentalCollection',
    }
  },

  methods: {
    assignTranslateInX(pValue) {
      this.showTransform = pValue
    },
  },
}
</script>

<style scoped>
.i-translate-in-pi {
  margin-top: 54px;
}
</style>
